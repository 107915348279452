<template>
  <div class="talent-authentication">
    <div class="scroll-box">
      <van-form
        ref="rcrz_form"
        class="rcrz-form"
        input-align="right"
        error-message-align="right"
        validate-trigger="onSubmit"
        :readonly="mode === 'view'"
        :colon="true"
        :scroll-to-error="true"
        :show-error="false"
        :show-error-message="true"
        :submit-on-enter="false"
        @submit="submitOnHandle"
      >
        <div class="form-moudlar no-margin-top">
          <div class="moudlar-title">
            <div class="title-text">学历信息</div>
          </div>
          <van-field
            required
            :readonly="mode === 'edit' ? smAuditStatus === 1 : true"
            v-model="form.realName"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '姓名不能为空' }]"
          />
          <van-field
            required
            :readonly="mode === 'edit' ? smAuditStatus === 1 : true"
            v-model="form.idCardNum"
            label="身份证号"
            placeholder="请输入身份证号"
            :rules="[
              { required: true, message: '身份证号不能为空' },
              { pattern: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/, message: '身份证号不正确' }
            ]"
          />
          <van-field
            readonly
            required
            v-model="form.graduateTime"
            label="毕业时间"
            placeholder="请选择毕业时间"
            right-icon="notes-o"
            :clickable="mode === 'edit'"
            :rules="[{ required: true, message: '毕业时间不能为空' }]"
            @click="mode === 'edit' ? (bysjShow = true) : ''"
          />
          <van-field
            required
            v-model="form.graduateSchool"
            label="毕业院校"
            placeholder="请输入毕业院校"
            :rules="[{ required: true, message: '毕业院校不能为空' }]"
          />
          <van-field
            readonly
            required
            v-model="form.highestEducation"
            label="最高学历"
            placeholder="请选择最高学历"
            right-icon="arrow"
            :clickable="mode === 'edit'"
            :rules="[{ required: true, message: '最高学历不能为空' }]"
            @click="mode === 'edit' ? (educationShow = true) : ''"
          />
          <van-field required v-model="form.major" label="所学专业" placeholder="请输入所学专业" :rules="[{ required: true, message: '所学专业不能为空' }]" />
        </div>
        <div class="form-moudlar">
          <div class="moudlar-title">
            <div class="title-text">学历证明</div>
          </div>
          <van-field
            class="work-photo"
            required
            label="学历证书"
            input-align="left"
            error-message-align="left"
            :rules="[{ required: true, message: '学历证书不能为空' }]"
          >
            <template #input>
              <v-upload
                ref=""
                class=""
                :imgUrl.sync="form.academicCertificateShow"
                :activityPicture.sync="form.academicCertificate"
                :maxCount="4"
                :readonly="mode === 'edit' ? smAuditStatus === 1 : true"
                :show-upload="mode === 'edit' ? smAuditStatus !== 1 : false"
                :deletable="mode === 'edit' ? smAuditStatus !== 1 : false"
              />
            </template>
          </van-field>
          <van-field
            class="work-photo"
            required
            label="学位证书"
            input-align="left"
            error-message-align="left"
            :rules="[{ required: true, message: '学位证书不能为空' }]"
          >
            <template #input>
              <v-upload
                ref=""
                class=""
                :imgUrl.sync="form.diplomaPicShow"
                :activityPicture.sync="form.diplomaPic"
                :maxCount="4"
                :readonly="mode === 'edit' ? smAuditStatus === 1 : true"
                :show-upload="mode === 'edit' ? smAuditStatus !== 1 : false"
                :deletable="mode === 'edit' ? smAuditStatus !== 1 : false"
              />
            </template>
          </van-field>
        </div>
        <div class="form-moudlar">
          <div class="moudlar-title">
            <div class="title-text">凭证上传<span class="sub-text">（身份证正反面照片）</span></div>
            <div
              v-show="!falseValues.includes(smAuditStatus)"
              :class="['smshzt-tag', { success: smAuditStatus === 1 }, { reject: smAuditStatus === 2 }, { inreview: smAuditStatus === 0 }]"
            >
              {{ smAuditStatusMap[smAuditStatus] }}
            </div>
          </div>
          <div class="pzsc-content">
            <v-upload
              ref=""
              class="sfzzpsc sfzzpsc-rx"
              :imgUrl.sync="form.idCardPhotoFrontShow"
              :activityPicture.sync="form.idCardPhotoFront"
              :maxCount="1"
              :readonly="mode === 'edit' ? smAuditStatus === 1 : true"
              :show-upload="mode === 'edit' ? smAuditStatus !== 1 : false"
              :deletable="mode === 'edit' ? smAuditStatus !== 1 : false"
            />
            <v-upload
              ref=""
              class="sfzzpsc sfzzpsc-gh"
              :imgUrl.sync="form.idCardPhotoBackShow"
              :activityPicture.sync="form.idCardPhotoBack"
              :maxCount="1"
              :readonly="mode === 'edit' ? smAuditStatus === 1 : true"
              :show-upload="mode === 'edit' ? smAuditStatus !== 1 : false"
              :deletable="mode === 'edit' ? smAuditStatus !== 1 : false"
            />
            <div class="tip-text"><img src="./img/hint.png" />拍摄时确保证件<span>边框完整、字体清晰、亮度均</span></div>
            <div class="error-text" v-show="sfzzpErrorText">{{ sfzzpErrorText }}</div>
          </div>
        </div>
      </van-form>
    </div>
    <div class="footer-btn">
      <van-button
        v-if="![1, 0].includes(rzAuditStatus)"
        size="small"
        round
        type="info"
        native-type="button"
        :loading="submitLoading"
        style="width: 80%"
        @click="submitOnClick"
      >
        {{ [2].includes(rzAuditStatus) ? '审核驳回，重新' : '' }}提交
      </van-button>
      <van-button v-if="[1].includes(rzAuditStatus)" size="small" round type="primary" native-type="button" style="width: 80%">审核通过</van-button>
      <van-button v-if="[0].includes(rzAuditStatus)" size="small" round type="warning" native-type="button" style="width: 80%">待审核</van-button>
    </div>

    <v-dateTimePicker
      type="date"
      :isAuto="true"
      :value="currentDate"
      :minDate="minDate"
      :maxDate="maxDate"
      :valueShow.sync="bysjShow"
      @confirmPicker="bysjOnOk"
    />
    <v-picker
      :defaultIndex="0"
      :columns="educationOptions"
      :valueShow="educationShow"
      valueKey="label"
      @clickOverlay="educationShow = false"
      @cancel="educationShow = false"
      @confirmPicker="educationOnOk"
    />
  </div>
</template>

<script>
import {
  userInfoUrl,
  getTalentsIntroduceInfoByUserId,
  saveOrUpdateTalentsIntroduce,
  getRyGhhyAuthInfoUrl,
  getUserRealNameAuthInfoUrl,
  getRyGhhyAuthDetailUrl,
  saveRyGhhyAuthInfoUrl
} from './api.js';
import { mapState } from 'vuex';
import moment from 'moment';
import { Button, Form } from 'vant';
import wx from 'weixin-js-sdk';
import _ from 'lodash';
import cardHeader from './img/card_header.png';
import cardEmblem from './img/card_emblem.png';

export default {
  name: 'talentAuthentication',
  components: {
    [Button.name]: Button,
    [Form.name]: Form
  },
  props: {},
  model: {},
  data() {
    return {
      mode: 'edit',
      educationOptions: [
        { label: '小学', value: 1 },
        { label: '初中', value: 2 },
        { label: '高中', value: 3 },
        { label: '大专', value: 4 },
        { label: '本科', value: 5 },
        { label: '硕士研究生', value: 6 },
        { label: '博士研究生', value: 7 }
      ],
      rzAuditStatus: '', // 认证审核状态（整型）: 0:待审核    1:审核通过    2:审核驳回
      rzAuditStatusMap: {
        1: '已认证',
        2: '审核驳回',
        0: '待审核'
      },
      smAuditStatus: '', // 实名审核状态（整型）: 0:待审核    1:审核通过    2:审核驳回
      smAuditStatusMap: {
        1: '已实名',
        2: '审核驳回',
        0: '待审核'
      },
      form: {
        type: 1,
        userId: '',
        tenantId: '',
        id: '',
        realNameId: '',
        realName: '', // 姓名
        idCardNum: '', // 身份证号
        graduateTime: '', // 毕业时间
        graduateSchool: '', // 毕业院校
        highestEducation: '', // 最高学历
        major: '', // 所学专业
        academicCertificate: '', // 学历证书
        academicCertificateShow: '', // 学历证书
        diplomaPic: '', // 学位证书
        diplomaPicShow: '', // 学位证书
        idCardPhotoFront: '', // 身份证正面照片
        idCardPhotoFrontShow: '',
        idCardPhotoBack: '', // 身份证反面照片
        idCardPhotoBackShow: ''
      },
      bysjShow: false,
      currentDate: null,
      minDate: null,
      maxDate: null,
      educationShow: false,
      sfzzpErrorText: '',
      submitLoading: false,
      falseValues: ['', null, void 0, NaN]
    };
  },
  computed: {
    ...mapState(['userId', 'tenantId', 'communityId', 'houseId'])
  },
  watch: {},
  created() {
    const currentDate = new Date();
    this.minDate = new Date(1900, 0, 1);
    this.maxDate = new Date(currentDate.getFullYear() + 10, currentDate.getMonth(), currentDate.getDate());
    this.currentDate = new Date(currentDate.getFullYear() - 5, currentDate.getMonth(), currentDate.getDate());

    this.getRyGhhySmxx();
    this.getYhSmxx();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    getRyGhhySmxx() {
      this.$axios.get(getRyGhhyAuthInfoUrl, { params: { type: '1', userId: this.userId } }).then(res => {
        if (res && res.code === 200) {
          res.data = res.data || {};
          Object.keys(this.form).forEach(k => {
            if (['academicCertificateShow', 'diplomaPicShow', 'idCardPhotoFrontShow', 'idCardPhotoBackShow'].includes(k)) {
              k === 'academicCertificateShow' && (this.form[k] = res.data.academicCertificate);
              k === 'diplomaPicShow' && (this.form[k] = res.data.diplomaPic);
            } else {
              !this.falseValues.includes(res.data[k]) && (this.form[k] = res.data[k] || '');
            }
          });
          this.currentDate = new Date(this.form.graduateTime.replace(/-/g, '/'));
          this.rzAuditStatus = res.data.auditStatus;
        }
      });
    },
    getYhSmxx() {
      this.$axios.get(getUserRealNameAuthInfoUrl, { params: { userId: this.userId } }).then(res => {
        if (res && res.code === 200) {
          res.data = res.data || {};
          this.smAuditStatus = res.data.auditStatus;
          this.form.realNameId = res.data.id;
          this.form.realName = res.data.realName;
          this.form.idCardNum = res.data.idCardNum;
          this.form.idCardPhotoFront = this.smAuditStatus === 1 ? '' : res.data.idCardPhotoFront;
          this.form.idCardPhotoFrontShow = this.smAuditStatus === 1 ? cardHeader : res.data.idCardPhotoFront;
          this.form.idCardPhotoBack = this.smAuditStatus === 1 ? '' : res.data.idCardPhotoBack;
          this.form.idCardPhotoBackShow = this.smAuditStatus === 1 ? cardEmblem : res.data.idCardPhotoBack;
        }
      });
    },
    submitOnHandle() {
      if (!this.sfzzpCheck()) return;
      this.submitLoading = true;
      const form = _.cloneDeep(this.form);
      Reflect.deleteProperty(form, 'academicCertificateShow');
      Reflect.deleteProperty(form, 'diplomaPicShow');
      Reflect.deleteProperty(form, 'idCardPhotoFrontShow');
      Reflect.deleteProperty(form, 'idCardPhotoBackShow');
      form.tenantId = this.tenantId;
      form.userId = this.userId;
      if (this.falseValues.includes(form.id)) {
        Reflect.deleteProperty(form, 'id');
      }
      if (this.smAuditStatus === 1) {
        Reflect.deleteProperty(form, 'realNameId');
        Reflect.deleteProperty(form, 'realName');
        Reflect.deleteProperty(form, 'idCardNum');
      }
      this.$axios
        .post(saveRyGhhyAuthInfoUrl, form)
        .then(res => {
          if (res && res.code == 200) {
            this.$toast(res.msg);
            this.$router.go(-1);
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    bysjOnOk(value) {
      if (!value) return;
      this.form.graduateTime = moment(value).format('YYYY-MM-DD');
      this.bysjShow = false;
    },
    educationOnOk(value) {
      if (!value) return;
      this.form.highestEducation = value.label;
      this.educationShow = false;
    },
    submitOnClick() {
      this.$refs.rcrz_form.submit();
    },
    sfzzpCheck() {
      if (!this.falseValues.includes(this.form.idCardPhotoFront) && !this.falseValues.includes(this.form.idCardPhotoBack)) {
        this.sfzzpErrorText = '';
        return true;
      } else {
        this.sfzzpErrorText = '请上传身份证正反面照片';
        document.querySelector('.pzsc-content').scrollIntoView({ behavior: 'instant', block: 'start', inline: 'start' });
        return false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
// constant(safe-area-inset-*)
// env(safe-area-inset-*)
// safe-area-inset-top
// safe-area-inset-right
// safe-area-inset-bottom
// safe-area-inset-left

.talent-authentication {
  box-sizing: border-box;
  height: 100%;
  background-color: #fafafa;
  padding-bottom: calc(32px + constant(safe-area-inset-bottom));
  padding-bottom: calc(32px + env(safe-area-inset-bottom));
  overflow: hidden;
}
.form-moudlar {
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 20px;
  box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
  &.no-margin-top {
    margin-top: 0px;
  }
}
.moudlar-title {
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 10px;
  margin: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title-text {
    box-sizing: border-box;
    font-size: 26px;
    line-height: 36px;
    height: 36px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    position: relative;
    .sub-text {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
.work-photo {
  flex-wrap: wrap;
  /deep/ .van-field__value {
    box-sizing: border-box;
    width: 100%;
    flex-shrink: 0;
    flex-basis: auto;
  }
}
.pzsc-content {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  /deep/ .v-upload {
    width: calc(50% - 10px);
    height: 200px;
    .van-uploader {
      width: 100%;
      height: 100%;
      .van-uploader__wrapper {
        width: 100%;
        height: 100%;
        .van-uploader__upload {
          width: 100%;
          height: 100%;
          .van-uploader__upload-icon {
            display: none;
          }
          .van-uploader__input {
            opacity: 0;
          }
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 100% 100%;
        }
        .van-uploader__preview,
        .van-uploader__preview-image {
          width: 100%;
          height: 100%;
          margin: 0px;
        }
      }
    }
    &.sfzzpsc-rx {
      .van-uploader__upload {
        background-image: url('./img/card_header.png');
      }
    }
    &.sfzzpsc-gh {
      .van-uploader__upload {
        background-image: url('./img/card_emblem.png');
      }
    }
  }
  .tip-text {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    margin-top: 10px;
    img {
      height: 24px;
      margin-bottom: -3px;
      margin-right: 8px;
    }
    span {
      color: #ff8c35;
    }
  }
  .error-text {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    font-size: 24px;
    color: #ee0a24;
    margin-top: 6px;
  }
}
.scroll-box {
  box-sizing: border-box;
  max-height: calc(100% - 40px - 32px);
  padding: 32px;
  overflow-x: hidden;
  overflow-y: auto;
}
.footer-btn {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 32px;
  position: absolute;
  left: 0px;
  bottom: 0px;
}
.smshzt-tag {
  box-sizing: border-box;
  flex-shrink: 0;
  height: 32px;
  padding: 0px 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border: 1px solid;
  &.success {
    color: #42db71;
    border-color: #42db71;
    background-color: rgba(66, 219, 113, 0.1);
  }
  &.inreview {
    color: #ff976a;
    border-color: #ff976a;
    background-color: rgba(255, 151, 106, 0.1);
  }
  &.reject {
    color: #ee0a24;
    border-color: #ee0a24;
    background-color: rgba(238, 10, 36, 0.1);
  }
}
</style>
